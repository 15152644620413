<template>
    <div class="mx-3">
      <h2 class="px-5 py-5">{{ $t('Resturant Report') }}</h2>
      <v-row class="px-10 py-1">
        <v-col md="3">
          <v-text-field  :dark="$store.state.isDarkMode"  :label="$t('hr.time.from-date')" type="date" 
                            v-model="filters.from" clearable>
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field :dark="$store.state.isDarkMode"  :label="$t('hr.time.to-date')" type="date" 
                            v-model="filters.to" clearable>
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-autocomplete v-model="filters.meal" :items="mealItems" item-text="name" item-value="id"
            :search-input.sync="mealSearch" :loading="meal.getMealsNameState.loading"
            :label="$t('meal name')" clearable></v-autocomplete>
        </v-col>
        <v-col md="3">
            <v-autocomplete 
          v-model="filters.student"
            :label="$t('student name')"
            clearable :items="childrens" :loading="childrensLoading" color="#757575" item-text="name"
            item-value="id"  @keypress="fetchRegRecordAutoComplete($event)"
            >
          </v-autocomplete>
        </v-col>
        <v-col md="2">
            <v-autocomplete
              color="#757575"
              :loading="eduClassesLoading"
              item-text="name"
              item-value="id"
              :label="$t('educitional class')"
              v-model="filters.edu_class"
              :items="eduClasses"
              clearable
            ></v-autocomplete>
        </v-col>
        <v-col md="2">
        <v-autocomplete
                color="#757575"
                item-text="name"
                item-value="id"
                :loading="gradesLoading"
                :label="$t('class')"
                v-model="filters.grade"
                :items="grades"
                clearable
            ></v-autocomplete>
          </v-col>
          <v-col md="2">
            <v-autocomplete
                color="#757575" 
                :label="$t('status')"
                :items="status"
                item-text="name"
                item-value="value"
                v-model="filters.status"
                clearable
              ></v-autocomplete>
          </v-col>
          <v-col md="2">
            <v-autocomplete
                :label="$t('Group')"
                v-model="filters.meal_group"
                color="#757575"
                item-text="name"
                item-value="id"
                :items="meal_group"
                clearable
            ></v-autocomplete>
          </v-col>
          <v-col md="2">
            <v-autocomplete
                color="#757575" 
                :label="$t('Group By')"
                :items="group_by"
                item-text="name"
                item-value="value"
                v-model="filters.group_by"
              ></v-autocomplete>
          </v-col>
          <v-col md="2">

          </v-col>

        <v-col md="3" class="mb-3 py-0">
          <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <v-btn color="blue" dark block  @click="exportToExcel(filters)"
            >{{ $t('hr.time.export') }}
            </v-btn>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <v-btn color="purple" dark block  @click="exportToExcelQuantities(filters)"
            >{{ $t('Export Quantities') }}
            </v-btn>
        </v-col>
      </v-row>
  


      <v-container id="regular-tables" fluid tag="section">
      <v-simple-table :dark="$store.state.isDarkMode">
        <thead class="table-heading" v-if="!loading">
          <tr>
            <th v-for="(name, i) in tableHeaders" :key="i">
              {{ $t(name) }}
            </th>
          </tr>
        </thead>
        <div class="table__spinnerWrapper" v-if="loading">
          <app-spinner></app-spinner>
        </div>
        <v-fade-transition mode="out-in" :group="true" tag="tbody" v-if="!loading">
          <template  v-for="data in tableItems" >
            <tr :key="data.uid" style=" background: var(--group-by-col)">
              
              <th colspan="8" style=" border: 0; font-size: 1rem">
                <span v-if="data.key"> {{ data.key }} </span>
              </th>
              
            </tr>
            <tr v-for="(item, idx) in data.value" :key="idx">
              
              <td>{{ item.date }}</td>
              <td>{{ item.student_name }}</td>
              <td>{{ item.edu_class_name }}</td>
              <td>{{ item.meal_name }}</td>
              <td>{{ item.price }}</td>
              <td>{{ item.group }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ $t(item.status) }}</td>
              
            </tr>
          </template>
        </v-fade-transition>
      </v-simple-table>
      <div class="d-flex justify-end mt-5">
        <v-row>
          <v-col md="5 ">
            <v-switch
              @change="goToAll"    
              v-model="is_all"
              color="green"
              :label="$t('Show All')"
            ></v-switch>
          </v-col>
        <app-pagination v-if="(!loading & (paginated))" :disable-pagination="!paginated" :totalPages="total" :page="page"
          @PaginationValue="applyPagination($event)"></app-pagination>
        </v-row>
      </div>
    </v-container>
   
    </div> 
  </template>
  
  <script>
  import { meal } from "../../../../store/auth";
  import axios from 'axios'
  import debounce from "../../../../helpers/debounce";
  import spinner from "../../../../components/base/spinner.vue";
  import pagination from "../../../../components/base/pagination.vue";
  import { saveAs } from 'file-saver';


  
  export default {
    components: {
    appSpinner: spinner,
    appPagination: pagination,
  },
    data: () => ({
      is_all: false,
      paginated: true,
      grades: null,
      gradesLoading: false,
      eduClasses: null,
      eduClassesLoading: false,
      childrens: null,
      childrensLoading: false,
      tableItems: [],
      meal,
      mealSearch: null,
      loading: false,
      page: 1,
      total: 1,
      tableHeaders: ["date", "student name", "educitional class", "meal name","price", "Group", "Amount", "status"],
      items: [],
      status: [
        {
          name: "مسلمة",
          value: "FULL",
        },
        {
          name: "غير مسلمة",
          value: "ZERO",
        },
        {
          name: "مسلمة جزئية",
          value: "PART",
        },
      ],
      group_by: [
        {
          name: "الشعبة",
          value: "edu_class_name",
        },
        {
          name: "الصف",
          value: "grade_name",
        },
        {
          name: "اسم الوجبة",
          value: "meal_name",
        },
        {
          name: "اسم الطالب",
          value: "student_name",
        },
        {
          name: "التاريخ",
          value: "date",
        },
      ],
      meal_group: null,
      filters: {
        student: null,
        edu_class: null,
        grade: null,
        meal: null,
        from: null,
        to: null,
        status: null,
        meal_group: null,
        group_by: "edu_class_name",
      },
    }),
    computed: {
    mealItems() {
      return meal.mealData;
    },
  },
  watch:{
    mealSearch(val) {
      if ((val != "") & (val != null)) {
        meal.getMealsName(val);
      } else {
        meal.mealData = [];
      }
    },
  },
    methods: {
      goToAll(){
      if(this.is_all) {
        this.paginated = false;
      } else { this.paginated = true; }
      this.getAll();
    },
      async fetchEduClasses() {
      try {
        this.eduClassesLoading = true;
        const eduClasses = await axios.get("/edu-class",{
          params: {
            paginated: false,
          }
        });
        this.eduClasses = eduClasses.data.classes;
      } catch (err) {
      } finally {
        this.eduClassesLoading = false;
      }
    },
    async fetchRegRecordAutoComplete(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.childrensLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/student/auto-complete", {
            params: {
              name: searchValue,
            },
          });
          this.childrens = searchResult.data.data;
          
        } catch (err) {
          console.log("err", err);
        } finally {
          this.childrensLoading = false;
        }
      }, 1000)();
    },
     async exportToExcel(queryParams){
         
          try {
     
      const excel = await axios.get(`/student_meal/export`, {
        params: {
          ...queryParams,
          
        },
        responseType: 'blob'
      });
      saveAs(excel.data)
      

    } catch (err) {
      console.log('err', err);
    } finally {
      
    }
      
      },
      async exportToExcelQuantities(queryParams){
         
         try {
    
     const excel = await axios.get(`/student_meal/${this.filters.group_by}/export`, {
       params: {
         ...queryParams,
         
       },
       responseType: 'blob'
     });
     saveAs(excel.data)
     

   } catch (err) {
     console.log('err', err);
   } finally {
     
   }
     
     },
   
      clearFilter() {
        this.filters.student =  undefined;
        this.filters.edu_class = undefined;
        this.filters.from = undefined;
        this.filters.to = undefined;
        this.filters.meal = undefined; 
        this.filters.grade = undefined;
        this.filters.meal_group = undefined;
        this.filters.status = undefined;
        this.page = 1;
        this.getAll();
      },
   
      async getAll() {
        try {
          this.loading = true;
          const filterTemp = {};
          Object.keys(this.filters).forEach((key) => {
            if (this.filters[key]) filterTemp[key] = this.filters[key];
          });
          
          
          const res = await axios.get("/student_meal/report", {
            params: {
              size: 10,
              page: this.page,
              paginated: this.paginated,
              ...filterTemp,
            },
          });
          const arr= Array.from(res.data.data)
          if(Array.isArray(arr))
          {
            console.log(arr);
            this.tableItems = arr.map((el) => {
              
              return {
                ...el,
                
              };
            });
          }
            console.log(res.data.data);
            this.tableItems.forEach((item) => {
          item.uid = this.$uuid.v1();
        });
          this.total = res.data.total;
          this.page = res.data.current_page;
        } finally {
          this.loading = false;
        }
      },
      applyPagination(paginationValue) {
      this.page = paginationValue;
      this.getAll();
    },
      async groupAc() {
            try {
          const searchResult = await axios.get(
            "/meal-group"
          );
          this.meal_group = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } 
      },
 
    },
    async created() {
      this.getAll();
      this.groupAc();
      this.fetchEduClasses();
      try {
      this.gradesLoading = true;
      const response = await axios.get("/grade", {
        params: {
          size: 100000,
        },
      });
      this.grades = response.data.grades;
    } catch (err) {
      console.log("err", err);
    } finally {
      this.gradesLoading = false;
    }
      
    },
  };
  </script>
  
  <style></style>
  